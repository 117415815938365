
import NetworkAlert from './Alerts/NetworkAlert.vue'
import { defineComponent, computed } from 'vue'
import { NButton, NText } from 'naive-ui'
import { BigNumber } from 'ethers'
import { useStore } from '../store'
import { truncateAddr, toDecimals } from '../utils/index'
import { headerNetwork, headerBalance } from '../i18n/main.json'
import WalletModal from './WalletModal.vue'

export default defineComponent({
  components: {
    NetworkAlert,
    NButton,
    NText,
    WalletModal
  },

  data: () => ({
    disabled: false,
    balance: BigNumber.from('0'),
    networkText: headerNetwork,
    balanceText: headerBalance,
    showAddressModal: false
  }),

  setup () {
    const store = useStore()

    return {
      // access a state in computed function
      walletAddress: computed(() => store.state.wallet.address),
      walletConnected: computed(() => store.state.wallet.connected),
      walletNetwork: computed(() => store.state.wallet.network),
      walletToken: computed(() => store.state.wallet.token),
      networkUnsupported: computed(() => store.state.wallet.networkUnsupported),
      tokenBalance: computed(() => store.state.multiProvider.balance),
      store
    }
  },

  methods: {
    truncateAddr,
    toDecimals,
    // connection should only be initiated in response to direct user action
    // button should be disabled while connection request is pending
    // never initiate connection request on page load
    async handleConnect () {
      // Disable button
      this.disabled = true
      await this.store.dispatch('connectWallet')
      this.disabled = false
      await setTimeout(async () => {
        await this.getBalanceFromWallet()
      }, 1000)
    },

    async getBalanceFromWallet () {
      this.store.dispatch('getBalanceFromWallet')
    }
  },

  computed: {
    metamaskInstalled (): boolean {
      const { ethereum } = window as any
      if (!ethereum) return false
      return !ethereum.isMetamask
    }
  }
})

