
import { computed, defineComponent } from 'vue'
import { NButton } from 'naive-ui'
import { useStore } from '../../store'
import { networks } from '../../config'

export default defineComponent({
  components: {
    NButton
  },

  data () {
    return {
      showAlert: false,
      availableNetworks: this.getAvailableNetworks()
    }
  },

  setup () {
    const store = useStore()

    return {
      // access a state in computed function
      connected: computed(() => store.state.wallet.connected),
      walletNetwork: computed(() => store.state.wallet.network),
      networkUnsupported: computed(() => store.state.wallet.networkUnsupported),
      store
    }
  },

  methods: {
    findNetwork () {
      const network = this.walletNetwork.toLowerCase()
      if (networks[network]) {
        this.showAlert = false
        return true
      } else {
        this.showAlert = true
        return false
      }
    },

    getAvailableNetworks () {
      return Object.keys(networks)
    },

    async switchNetwork (network: string) {
      await this.store.dispatch('setWalletNetwork', network)
    }
  },

  watch: {
    walletNetwork (newNetwork, oldNetwork) {
      if (newNetwork !== oldNetwork) {
        this.findNetwork()
      }
    }
  },

  computed: {
    mmConnected () {
      const { ethereum } = window as any
      if (!ethereum) {
        return false
      } else {
        return ethereum.isConnected()
      }
    }
  }
})
