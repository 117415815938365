
import { defineComponent } from 'vue'
import { NAlert } from 'naive-ui'
import { useStore } from '../../store'

export default defineComponent({
  components: {
    NAlert
  },

  props: {
    title: {
      type: String,
      default: 'Alert'
    },
    message: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: 'default'
    },
    timerID: {
      type: Number
    }
  },
  data () {
    return {
      timer: this.timerID
    }
  },
  setup () {
    const store = useStore()

    return {
      store
    }
  },

  methods: {
    retainPopup () {
      this.store.dispatch('retainPopup', this.timer)
    },
    handleClose () {
      this.store.dispatch('removePopup', this.timer)
    }
  }
})
