
import { defineComponent, computed } from 'vue'
import { NCard } from 'naive-ui'

import CopyHash from './CopyHash.vue'
import { useStore } from '@/store'
import { supportLink, successfulTx, saveTxHash } from '@/i18n/main.json'

export default defineComponent({
  components: {
    NCard,
    CopyHash
  },

  props: {
    txHash: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      // copy
      supportLink,
      successfulTx,
      saveTxHash
    }
  },

  setup () {
    const store = useStore()
    const newTransaction = computed(() => store.state.transactions.newTransaction)
    return {
      store,
      newTransaction
    }
  }
})
