
import { defineComponent, computed } from 'vue'
import { NCard, NTabs, NTabPane } from 'naive-ui'

import { useStore } from '../store'
import router from '../router'
import { firstTab, thirdTab } from '../i18n/main.json'

export default defineComponent({
  components: {
    NCard,
    NTabs,
    NTabPane
  },

  data () {
    return {
      firstTab,
      // secondTab,
      thirdTab
    }
  },

  setup () {
    const store = useStore()

    return {
      store,
      newTransaction: computed(() => store.state.transactions.newTransaction),
      newestTransaction: computed(() => store.state.transactions.transactions[0])
    }
  },

  methods: {
    navigate (path: string) {
      if (path === '/') {
        this.store.dispatch('newTransaction', false)
      }
      router.push(path)
    },

    async sendToDetails () {
      const network = await this.store.getters.resolveDomainName(this.newestTransaction.network)
      router.push(`/search-transaction?network=${network}&hash=${this.newestTransaction.hash}`)
    }
  },

  watch: {
    newTransaction (newTr, oldTr) {
      if (newTr !== oldTr && newTr) {
        this.sendToDetails()
      }
    }
  },

  computed: {
    activeTab (): string {
      switch (this.$route.name) {
        case 'Bridge':
          return firstTab
        // case 'Transactions History':
        //   return secondTab
        case 'Transaction Search':
          return thirdTab
        default:
          return firstTab
      }
    }
  }
})
