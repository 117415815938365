import { createWebHistory, createRouter } from 'vue-router'

import TransactionForm from '../components/TransactionForm/TransactionForm.vue'
// import Transactions from '../components/Transactions/Transactions.vue'
import TransactionSearch from '../components/Transactions/TransactionSearch.vue'

const routes = [
  // {
  //   path: '/transactions',
  //   name: 'Transactions History',
  //   component: Transactions
  // },
  {
    path: '/search-transaction',
    name: 'Transaction Search',
    component: TransactionSearch
  },
  {
    path: '/',
    name: 'Bridge',
    component: TransactionForm
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
