
import { defineComponent, computed } from 'vue'
import { NModal, NCard, NText, NRadio, NDivider, NButton, NIcon } from 'naive-ui'
import { AddOutline } from '@vicons/ionicons5'
import { representations, tokens, ZERO_ADDRESS } from '../config'
import { useStore } from '../store'
import CopyHash from './Transactions/CopyHash.vue'

export default defineComponent({
  components: {
    NModal,
    NCard,
    NText,
    NRadio,
    NDivider,
    NButton,
    NIcon,
    AddOutline,
    CopyHash
  },

  props: {
    show: Boolean
  },

  data () {
    return {
      representations,
      zeroAddress: ZERO_ADDRESS
    }
  },

  setup () {
    const store = useStore()

    return {
      // access a state in computed function
      walletAddress: computed(() => store.state.wallet.address),
      walletConnected: computed(() => store.state.wallet.connected),
      store
    }
  },

  methods: {
    async addToken (network: string, key: string, address: string) {
      const token = tokens[key]
      await this.store.dispatch('setWalletNetwork', network)
      try {
        // wasAdded is a boolean. Like any RPC method, an error may be thrown.
        const wasAdded = await (window as any).ethereum.request({
          method: 'wallet_watchAsset',
          params: {
            type: 'ERC20', // Initially only supports ERC20, but eventually more!
            options: {
              address,
              symbol: token.symbol,
              decimals: token.decimals,
              image: token.icon
            }
          }
        })

        if (wasAdded) {
          console.log('Token Added')
        } else {
          console.log('Error adding token')
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
})
