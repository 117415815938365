// wallet
export const SET_WALLET_CONNECTION = 'wallet/SET_WALLET_CONNECTION'
export const SET_WALLET_ADDRESS = 'wallet/SET_WALLET_ADDRESS'
export const SET_WALLET_NETWORK = 'wallet/SET_WALLET_NETWORK'
export const SET_WALLET_TOKEN = 'wallet/SET_WALLET_TOKEN'
export const SET_NETWORK_UNSUPPORTED = 'wallet/SET_NETWORK_UNSUPPORTED'

// userInput
export const SET_RECIPIENT_ADDRESS = 'userInput/SET_RECIPIENT_ADDRESS'
export const SET_SEND_AMOUNT = 'userInput/SET_SEND_AMOUNT'
export const SET_DESTINATION_NETWORK = 'userInput/SET_DESTINATION_NETWORK'
export const SET_SHOW_DISCLAIMER = 'userInput/SET_SHOW_DISCLAIMER'

// multi-provider
export const SET_BALANCE = 'mp/SET_BALANCE'
export const SET_SENDING = 'mp/SET_SENDING'

// transactions
export const SET_TRANSACTION = 'transactions/SET_TRANSACTION'
export const SET_NEW_TRANSACTION = 'transactions/SET_NEW_TRANSACTION'
export const REMOVE_TRANSACTION = 'transactions/REMOVE_TRANSACTION'

// popups
export const ADD_POPUP_INFO = 'popups/ADD_POPUP_INFO'
export const REMOVE_POPUP_INFO = 'popups/REMOVE_POPUP_INFO'

// sanctions
export const SET_SANCTIONED_LIST = 'sanctions/SET_SANCTIONED_LIST'
