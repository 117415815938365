import { MutationTree, ActionTree } from 'vuex'
import * as types from '../mutation-types'

interface PopupInfo {
  type: string;
  title: string;
  message: string;
  timerID: number;
}

export interface PopupState {
  list: Array<PopupInfo>
}

const popupModule = {
  state: () => ({
    list: []
  }),

  mutations: <MutationTree<PopupState>>{
    [types.ADD_POPUP_INFO] (state: PopupState, info: PopupInfo) {
      state.list.push(info)
    },
    [types.REMOVE_POPUP_INFO] (state: PopupState, id: number) {
      const index = state.list.findIndex(popup => {
        const pIndex = Object.values(popup).indexOf(id)
        return pIndex > -1
      })
      state.list.splice(index, 1)
    }
  },

  actions: <ActionTree<PopupState, any>>{
    async addPopup ({ commit, state }, info: PopupInfo) {
      const timer = setTimeout(() => {
        commit(types.REMOVE_POPUP_INFO, timer)
      }, 5000)
      Object.assign(info, { timerID: timer })
      await commit(types.ADD_POPUP_INFO, info)
    },
    retainPopup ({ state }, timerID: any) {
      clearTimeout(timerID)
    },
    removePopup ({ commit, state }, id: number) {
      commit(types.REMOVE_POPUP_INFO, id)
    },
    addInfoPopup ({ dispatch }, info: PopupInfo) {
      const defaultInfo = {
        type: 'info',
        title: 'Info'
      }
      Object.assign(defaultInfo, info)
      dispatch('addPopup', defaultInfo)
    },
    addSuccessPopup ({ dispatch }, info: PopupInfo) {
      const successInfo = {
        type: 'success',
        title: 'Success'
      }
      Object.assign(successInfo, info)
      dispatch('addPopup', successInfo)
    },
    addWarningPopup ({ dispatch }, info: PopupInfo) {
      const warningInfo = {
        type: 'warning',
        title: 'Warning'
      }
      Object.assign(warningInfo, info)
      dispatch('addPopup', warningInfo)
    },
    addErrorPopup ({ dispatch }, info: PopupInfo) {
      const errorInfo = {
        type: 'error',
        title: 'Error'
      }
      Object.assign(errorInfo, info)
      dispatch('addPopup', errorInfo)
    }
  }
}

export default popupModule
