
import { defineComponent } from 'vue'
import { NTooltip, NIcon, NButton } from 'naive-ui'
import { CopyOutline } from '@vicons/ionicons5'

export default defineComponent({
  props: {
    value: {
      type: String,
      required: true
    },
    text: {
      type: Boolean,
      default: false
    }
  },

  components: {
    NTooltip,
    NIcon,
    NButton,
    CopyOutline
  },

  data () {
    return {
      copyText: 'copy'
    }
  },

  methods: {
    handleCopy () {
      const self = this
      navigator.clipboard.writeText(this.value!).then(function () {
        console.log('Async: Copying to clipboard was successful!')
        self.copyText = 'copied'
      }, function (err) {
        console.error('Async: Could not copy text: ', err)
      })
    },

    reset () {
      setTimeout(() => {
        this.copyText = 'copy'
      }, 500)
    }
  }
})

