// TODO: export from multiprovider
export type TokenIdentifier = {
  domain: string | number
  id: string
}

export type mainTokenName =
  'SUSHI'
  | 'WETH'
  | 'WBTC'
  | 'UNI'
  | 'LINK'
  | 'USDC'
  | 'USDT'
  | 'DAI'
  | 'CELO'
  | 'cUSD'
  | 'cEUR'
  | 'WMATIC'
  | 'KNX'
  | 'SOURCE'
  | 'SYMM'
  | 'PACT'
  | 'CRV'
  | 'SDT'
  | 'D2D'

// IMPORTANT make name same as tokens[token].symbol value
const SUSHI: TokenIdentifier = {
  domain: 'ethereum',
  id: '0x6b3595068778dd592e39a122f4f5a5cf09c90fe2'
}

const WETH: TokenIdentifier = {
  domain: 'ethereum',
  id: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2'
}

const WBTC: TokenIdentifier = {
  domain: 'ethereum',
  id: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599'
}

const UNI: TokenIdentifier = {
  domain: 'ethereum',
  id: '0x1f9840a85d5af5bf1d1762f925bdaddc4201f984'
}

const LINK: TokenIdentifier = {
  domain: 'ethereum',
  id: '0x514910771af9ca656af840dff83e8264ecf986ca'
}

const USDC: TokenIdentifier = {
  domain: 'ethereum',
  id: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48'
}

const USDCPOS: TokenIdentifier = {
  domain: 'polygon',
  id: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174'
}

const USDT: TokenIdentifier = {
  domain: 'ethereum',
  id: '0xdac17f958d2ee523a2206206994597c13d831ec7'
}

const DAI: TokenIdentifier = {
  domain: 'ethereum',
  id: '0x6b175474e89094c44da98b954eedeac495271d0f'
}

const CELO: TokenIdentifier = {
  domain: 'celo',
  id: '0x471EcE3750Da237f93B8E339c536989b8978a438'
}

const cUSD: TokenIdentifier = {
  domain: 'celo',
  id: '0x765DE816845861e75A25fCA122bb6898B8B1282a'
}

const cEUR: TokenIdentifier = {
  domain: 'celo',
  id: '0xD8763CBa276a3738E6DE85b4b3bF5FDed6D6cA73'
}

const WMATIC: TokenIdentifier = {
  domain: 'polygon',
  id: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270'
}

const KNX: TokenIdentifier = {
  domain: 'celo',
  id: '0xa81D9a2d29373777E4082d588958678a6Df5645c'
}

const SYMM: TokenIdentifier = {
  domain: 'ethereum',
  id: '0x57db3ffca78dbbe0efa0ec745d55f62aa0cbd345'
}

const SOURCE: TokenIdentifier = {
  domain: 'celo',
  id: '0x74c0C58B99b68cF16A717279AC2d056A34ba2bFe'
}

const PACT: TokenIdentifier = {
  domain: 'celo',
  id: '0x46c9757c5497c5b1f2eb73ae79b6b67d119b0b58'
}

const WAVAX: TokenIdentifier = {
  domain: 'avalanche',
  id: '0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7'
}

const CRV: TokenIdentifier = {
  domain: 'ethereum',
  id: '0xd533a949740bb3306d119cc777fa900ba034cd52'
}

const SDT: TokenIdentifier = {
  domain: 'ethereum',
  id: '0x73968b9a57c6e53d41345fd57a6e6ae27d6cdb2f'
}

const D2D: TokenIdentifier = {
  domain: 'ethereum',
  id: '0x43D4A3cd90ddD2F8f4f693170C9c8098163502ad'
}

export default {
  SUSHI,
  WETH,
  WBTC,
  UNI,
  LINK,
  USDC,
  USDCPOS,
  USDT,
  DAI,
  CELO,
  cUSD,
  cEUR,
  WMATIC,
  KNX,
  SYMM,
  SOURCE,
  WAVAX,
  PACT,
  CRV,
  SDT,
  D2D
}
