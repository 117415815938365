import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "body" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Nav = _resolveComponent("Nav")!
  const _component_Popup_List = _resolveComponent("Popup-List")!
  const _component_Logo_Spin = _resolveComponent("Logo-Spin")!
  const _component_Modal = _resolveComponent("Modal")!
  const _component_Footer = _resolveComponent("Footer")!
  const _component_n_config_provider = _resolveComponent("n-config-provider")!

  return (_openBlock(), _createBlock(_component_n_config_provider, {
    theme: _ctx.darkTheme,
    "theme-overrides": _ctx.themeOverrides,
    class: "container"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Nav),
      _createVNode(_component_Popup_List),
      _createElementVNode("div", _hoisted_1, [
        (_ctx.loading || _ctx.networkUnsupported)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_Logo_Spin)
            ]))
          : (_openBlock(), _createBlock(_component_Modal, { key: 1 }))
      ]),
      _createVNode(_component_Footer, { class: "footer" })
    ]),
    _: 1
  }, 8, ["theme", "theme-overrides"]))
}