
import { defineComponent } from 'vue'

export default defineComponent({
  computed: {
    metamaskInstalled (): boolean {
      const { ethereum } = window as any
      if (!ethereum) return false
      return !ethereum.isMetamask
    }
  }
})
