/**
 * UserInput Module contains the user-inputed information gathered for
 * submission. Rather than dealing with emits and props between many components
 * handling them in the store makes it easier to deal with data changes
 */
import { MutationTree, ActionTree, GetterTree } from 'vuex'
import * as types from '../mutation-types'
import { BigNumber } from 'ethers'

export interface UserInputState {
  recipientAddress: string;
  sendAmount: number;
  destinationNetwork: String | null;
  showDisclaimer: boolean;
}

const state: UserInputState = {
  recipientAddress: '',
  sendAmount: 0,
  destinationNetwork: null,
  showDisclaimer: true
}

const mutations = <MutationTree<UserInputState>> {
  [types.SET_RECIPIENT_ADDRESS] (state: UserInputState, address: string) {
    console.log('{dispatch} set recipient address: ', address)
    state.recipientAddress = address
  },

  // Amount comes in as a number. We should store it as a number
  [types.SET_SEND_AMOUNT] (state: UserInputState, amount: number) {
    console.log('{dispatch} set send amount: ', amount)
    state.sendAmount = amount
  },

  [types.SET_DESTINATION_NETWORK] (state: UserInputState, network: string) {
    console.log('{dispatch} set destination network: ', network)
    state.destinationNetwork = network
  },

  [types.SET_SHOW_DISCLAIMER] (
    state: UserInputState, show: boolean
  ) {
    console.log('{dispatch} set showDisclaimer: ', show)
    state.showDisclaimer = show
  }
}

const actions = <ActionTree<UserInputState, any>> {
  async setRecipientAddress ({ commit }, address: string) {
    commit(types.SET_RECIPIENT_ADDRESS, address)
  },

  setSendAmount ({ commit }, amount: number) {
    commit(types.SET_SEND_AMOUNT, amount)
  },

  setDestinationNetwork ({ commit }, network: string) {
    commit(types.SET_DESTINATION_NETWORK, network)
  },

  clearDestinationNetwork ({ commit }) {
    commit(types.SET_DESTINATION_NETWORK, null)
  },

  dismissDisclaimer ({ commit }) {
    commit(types.SET_SHOW_DISCLAIMER, false)
  }
}

const getters = <GetterTree<UserInputState, any>> {
  // Transforms amount into BigNumber for comparison to balance
  getSendAmountAsBN (state: UserInputState) {
    return BigNumber.from(state.sendAmount)
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
