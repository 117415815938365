
import { defineComponent } from 'vue'
import {
  githubLink,
  supportLink,
  twitterLink,
  mediumLink,
  telegramLink,
  docsLink
} from '../i18n/main.json'

export default defineComponent({
  data () {
    return {
      githubLink,
      supportLink,
      twitterLink,
      mediumLink,
      telegramLink,
      docsLink
    }
  }
})
