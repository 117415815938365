
import { defineComponent, ref, computed } from 'vue'
import { NCard, NSelect, NForm } from 'naive-ui'

import AssetLogoIcon from './AssetLogoIcon.vue'
import Disclaimer from './Disclaimer.vue'
import BalanceAndSendAmt from './BalanceAndSendAmt.vue'
import Send from './Send.vue'
import RecipientAddress from './RecipientAddress.vue'
import LogoWheel from '../LogoWheel.vue'
import { useStore } from '../../store'
import * as copy from '../../i18n/main.json'
import {
  generateTokenOptions,
  generateNetworkOptions,
  destinationNetworks,
  getTokenByName,
  getRepresentationalAssetFromNative
} from '../../utils'

export default defineComponent({
  components: {
    NCard,
    NSelect,
    NForm,
    AssetLogoIcon,
    Disclaimer,
    BalanceAndSendAmt,
    RecipientAddress,
    Send,
    LogoWheel
  },

  data () {
    return {
      // user should not be able to accept same network as current/from network
      destinationNetworkOptions: destinationNetworks(this.originNetworkOptions, this.walletNetwork),

      // copy
      headerFrom: copy.headerFrom,
      headerTo: copy.headerTo,
      toNetworkDefault: copy.toNetworkDefault
    }
  },

  setup () {
    const store = useStore()
    const walletAddress = computed(() => store.state.wallet.address)
    const walletNetwork = computed(() => store.state.wallet.network)
    const assetOptions = computed(() => generateTokenOptions(store.state.wallet.network))
    const walletToken = computed(() => store.state.wallet.token)
    const walletTokenName = computed(() => store.state.wallet.token.name)
    const sendAmount = computed(() => store.state.userInput.sendAmount)
    const destinationNetwork = computed(() => store.state.userInput.destinationNetwork)
    const sending = computed(() => store.state.multiProvider.sending)

    return {
      formRef: ref(null),
      formModel: ref({
        asset: walletTokenName,
        originNetwork: walletNetwork,
        destinationNetwork: destinationNetwork,
        sendAmount: sendAmount,
        recipientAddress: walletAddress.value
      }),
      assetOptions,
      originNetworkOptions: generateNetworkOptions(),
      recipientAddress: computed(() => store.state.userInput.recipientAddress),
      walletNetwork,
      walletToken,
      sending,
      store
    }
  },

  methods: {
    handleAssetChange (newAsset: string) {
      const token = getTokenByName(newAsset)
      // TODO: Make setToken wait until balance is retrieved before changing
      // so that there isn't a weird flash
      // OR turn balance into spinning icon while balance is retrieved
      this.store.dispatch('setToken', token)
    },

    async handleOriginNetworkChange (originNetwork: string) {
      await this.store.dispatch('setWalletNetwork', originNetwork)

      // user should not be able to select same destination network as from network
      this.destinationNetworkOptions = destinationNetworks(this.originNetworkOptions, this.walletNetwork)
      if (this.walletNetwork === this.formModel.destinationNetwork) {
        this.store.dispatch('clearDestinationNetwork')
      }

      // user should not be able to access native ETH assets on non-native networks
      const assetFound = this.assetOptions.find(o => o.value === this.formModel.asset)
      if (!assetFound) {
        // native asset is not an option on current network
        // switch to representational asset and alert user
        const token = getRepresentationalAssetFromNative(this.formModel.asset)
        const payload = { message: `${this.formModel.asset} is a native asset that doesn't exist on ${originNetwork}. Asset switched to ${token.name}.` }
        this.store.dispatch('addInfoPopup', payload)
        this.store.dispatch('setToken', token)
      }
    },

    async handleDestNetworkChange (destNetwork: string) {
      await this.store.dispatch('setDestinationNetwork', destNetwork)
    }
  }
})
