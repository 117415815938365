import AVAXIcon from '../assets/token-logos/AVAX.png'
import CeloIcon from '../assets/token-logos/CELO.png'
import wBTCIcon from '../assets/token-logos/wbtc.png'
import wETHIcon from '../assets/token-logos/weth.png'
import MaticIcon from '../assets/token-logos/MATIC.png'
import SushiIcon from '../assets/token-logos/sushi.png'
import cEURIcon from '../assets/token-logos/cEUR.png'
import cUSDIcon from '../assets/token-logos/cUSD.png'
import USDTIcon from '../assets/token-logos/USDT.png'
import USDCIcon from '../assets/token-logos/USDC.png'
import DAIIcon from '../assets/token-logos/DAI.png'
import KNXIcon from '../assets/token-logos/KNX.png'
import SOURCEIcon from '../assets/token-logos/SOURCE.png'
import SYMMIcon from '../assets/token-logos/SYMM.png'
import PACTIcon from '../assets/token-logos/PACT.png'
import CRVIcon from '../assets/token-logos/CRV.png'
import UNIIcon from '../assets/token-logos/UNI.png'
import SDTIcon from '../assets/token-logos/SDT.png'
import D2DIcon from '../assets/token-logos/D2D.png'

import mainnetTokens from './tokens.main'
import { TokenMetadata, NetworkMetadata } from './config.types'
import representationsMain from './representations.main'

// TODO: get rid on name, just use symbol
export const tokens: { [key: string]: TokenMetadata } = {
  CELO: {
    nativeNetwork: 'celo',
    symbol: 'CELO',
    name: 'CELO',
    icon: CeloIcon,
    decimals: 18,
    coinGeckoId: 'celo',
    tokenIdentifier: mainnetTokens.CELO,
    nativeOnly: false,
    minAmt: 1.8
  },
  WETH: {
    nativeNetwork: 'ethereum',
    symbol: 'WETH',
    name: 'WETH',
    icon: wETHIcon,
    decimals: 18,
    coinGeckoId: 'weth',
    tokenIdentifier: mainnetTokens.WETH,
    nativeOnly: false,
    minAmt: 0.0028
  },
  WBTC: {
    nativeNetwork: 'ethereum',
    symbol: 'WBTC',
    name: 'WBTC',
    icon: wBTCIcon,
    decimals: 8,
    coinGeckoId: 'wbtc',
    tokenIdentifier: mainnetTokens.WBTC,
    nativeOnly: false,
    minAmt: 0.0000017
  },
  ETH: {
    nativeNetwork: 'ethereum',
    symbol: 'ETH',
    name: 'ETH',
    icon: wETHIcon,
    decimals: 18,
    coinGeckoId: 'ethereum',
    tokenIdentifier: mainnetTokens.WETH,
    nativeOnly: true,
    minAmt: 0.0028
  },
  DAI: {
    nativeNetwork: 'ethereum',
    symbol: 'DAI',
    name: 'DAI',
    icon: DAIIcon,
    decimals: 18,
    coinGeckoId: 'dai',
    tokenIdentifier: mainnetTokens.DAI,
    nativeOnly: false,
    minAmt: 10
  },
  WMATIC: {
    nativeNetwork: 'polygon',
    symbol: 'WMATIC',
    name: 'WMATIC',
    icon: MaticIcon,
    decimals: 18,
    coinGeckoId: 'wmatic',
    tokenIdentifier: mainnetTokens.WMATIC,
    nativeOnly: false,
    minAmt: 8
  },
  MATIC: {
    nativeNetwork: 'polygon',
    symbol: 'MATIC',
    name: 'MATIC',
    icon: MaticIcon,
    decimals: 18,
    coinGeckoId: 'polygon',
    tokenIdentifier: mainnetTokens.WMATIC,
    nativeOnly: true,
    minAmt: 8
  },
  CUSD: {
    nativeNetwork: 'celo',
    symbol: 'cUSD',
    name: 'cUSD',
    icon: cUSDIcon,
    decimals: 18,
    coinGeckoId: 'celo-dollar',
    tokenIdentifier: mainnetTokens.cUSD,
    nativeOnly: false,
    minAmt: 10
  },
  CEUR: {
    nativeNetwork: 'celo',
    symbol: 'cEUR',
    name: 'cEUR',
    icon: cEURIcon,
    decimals: 18,
    coinGeckoId: 'celo-euro',
    tokenIdentifier: mainnetTokens.cEUR,
    nativeOnly: false,
    minAmt: 8.5
  },
  USDT: {
    nativeNetwork: 'ethereum',
    symbol: 'USDT',
    name: 'USDT',
    icon: USDTIcon,
    decimals: 6,
    coinGeckoId: 'tether',
    tokenIdentifier: mainnetTokens.USDT,
    nativeOnly: false,
    minAmt: 10
  },
  USDC: {
    nativeNetwork: 'ethereum',
    symbol: 'USDC',
    name: 'USDC',
    icon: USDCIcon,
    decimals: 6,
    coinGeckoId: 'usd-coin',
    tokenIdentifier: mainnetTokens.USDC,
    nativeOnly: false,
    minAmt: 10
  },
  USDCPOS: {
    nativeNetwork: 'polygon',
    symbol: 'USDC (PoS)',
    name: 'USDC (PoS)',
    icon: USDCIcon,
    decimals: 6,
    coinGeckoId: 'usd-coin',
    tokenIdentifier: mainnetTokens.USDCPOS,
    nativeOnly: false,
    minAmt: 10
  },
  UNI: {
    nativeNetwork: 'ethereum',
    symbol: 'UNI',
    name: 'UNI',
    icon: UNIIcon,
    decimals: 18,
    coinGeckoId: 'uniswap',
    tokenIdentifier: mainnetTokens.UNI,
    nativeOnly: false,
    minAmt: 5
  },
  CRV: {
    nativeNetwork: 'ethereum',
    symbol: 'CRV',
    name: 'CRV',
    icon: CRVIcon,
    decimals: 18,
    coinGeckoId: 'curve-dao-token',
    tokenIdentifier: mainnetTokens.CRV,
    nativeOnly: false,
    minAmt: 10
  },
  SUSHI: {
    nativeNetwork: 'ethereum',
    symbol: 'SUSHI',
    name: 'SUSHI',
    icon: SushiIcon,
    decimals: 18,
    coinGeckoId: 'sushi',
    tokenIdentifier: mainnetTokens.SUSHI,
    nativeOnly: false,
    minAmt: 1
  },
  KNX: {
    nativeNetwork: 'celo',
    symbol: 'KNX',
    name: 'KNX',
    icon: KNXIcon,
    decimals: 18,
    // KNX isn't listed on coin gecko yet
    coinGeckoId: 'foo',
    tokenIdentifier: mainnetTokens.KNX,
    nativeOnly: false,
    minAmt: 10
  },
  SOURCE: {
    nativeNetwork: 'celo',
    symbol: 'SOURCE',
    name: 'SOURCE',
    icon: SOURCEIcon,
    decimals: 18,
    // SOURCE isn't listed on coin gecko yet
    coinGeckoId: 'foo',
    tokenIdentifier: mainnetTokens.SOURCE,
    nativeOnly: false,
    minAmt: 10
  },
  PACT: {
    nativeNetwork: 'celo',
    symbol: 'PACT',
    name: 'PACT',
    icon: PACTIcon,
    decimals: 18,
    coinGeckoId: 'impactmarket',
    tokenIdentifier: mainnetTokens.PACT,
    nativeOnly: false,
    minAmt: 10
  },
  SDT: {
    nativeNetwork: 'ethereum',
    symbol: 'SDT',
    name: 'SDT',
    icon: SDTIcon,
    decimals: 18,
    coinGeckoId: 'stake-dao',
    tokenIdentifier: mainnetTokens.SDT,
    nativeOnly: false,
    minAmt: 10
  },
  D2D: {
    nativeNetwork: 'ethereum',
    symbol: 'D2D',
    name: 'D2D',
    icon: D2DIcon,
    decimals: 18,
    coinGeckoId: 'prime',
    tokenIdentifier: mainnetTokens.D2D,
    nativeOnly: false,
    minAmt: 50
  },
  SYMM: {
    nativeNetwork: 'ethereum',
    symbol: 'SYMM',
    name: 'SYMM',
    icon: SYMMIcon,
    decimals: 18,
    coinGeckoId: 'symmetric',
    tokenIdentifier: mainnetTokens.SYMM,
    nativeOnly: false,
    minAmt: 2.5 // equivalent to $10
  },
  WAVAX: {
    nativeNetwork: 'avalanche',
    symbol: 'WAVAX',
    name: 'WAVAX',
    icon: AVAXIcon,
    decimals: 18,
    coinGeckoId: 'wrapped-avax',
    tokenIdentifier: mainnetTokens.WAVAX,
    nativeOnly: false,
    minAmt: 0.074 // $10 worth at the time of writing
  },
  AVAX: {
    nativeNetwork: 'avalanche',
    symbol: 'AVAX',
    name: 'AVAX',
    icon: AVAXIcon,
    decimals: 18,
    coinGeckoId: 'avalanche',
    tokenIdentifier: mainnetTokens.WAVAX,
    nativeOnly: true,
    minAmt: 0.074 // $10 worth at the time of writing
  }
}

export const networks: { [key: string]: NetworkMetadata } = {
  celo: {
    name: 'Celo',
    chainID: 42220,
    domainID: 1667591279,
    rpcUrl: 'https://forno.celo.org',
    nativeToken: tokens.CELO,
    blockExplorer: 'https://explorer.celo.org'
  },
  ethereum: {
    name: 'Ethereum',
    chainID: 1,
    domainID: 6648936,
    rpcUrl: 'https://eth-mainnet.alchemyapi.io/v2/hPY2z3xUkRrFVnUDTEA247ogtEtbszHV',
    nativeToken: tokens.ETH,
    blockExplorer: 'https://etherscan.io/'
  },
  polygon: {
    name: 'Polygon',
    chainID: 137,
    domainID: 1886350457,
    nativeToken: tokens.MATIC,
    rpcUrl: 'https://polygon-mainnet.infura.io/v3/d6c87ec3380e4b57a9ba53aaecaddffc',
    blockExplorer: 'https://polygonscan.com/'
  }
}

export const representations = representationsMain
export const s3BucketUrl = 'https://optics-production-community-proofs.s3.us-west-2.amazonaws.com/'
export const requiresManualProcessing = (remote: string) => remote === 'ethereum'
