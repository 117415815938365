
import { defineComponent } from 'vue'
import { NText, NIcon, NSpin, NButton } from 'naive-ui'
import { RefreshOutline } from '@vicons/ionicons5'

export default defineComponent({
  props: {
    value: String,
    loading: Boolean
  },

  components: {
    NText,
    NIcon,
    NSpin,
    NButton,
    RefreshOutline
  },

  methods: {
    refresh () {
      this.$emit('refresh')
    }
  }
})

