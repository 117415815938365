import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_tab_pane = _resolveComponent("n-tab-pane")!
  const _component_n_tabs = _resolveComponent("n-tabs")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_n_card = _resolveComponent("n-card")!

  return (_openBlock(), _createBlock(_component_n_card, { class: "card" }, {
    default: _withCtx(() => [
      _createVNode(_component_n_tabs, {
        value: _ctx.activeTab,
        size: "large"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_n_tab_pane, {
            name: _ctx.firstTab,
            tab: _ctx.firstTab,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.navigate('/')))
          }, null, 8, ["name", "tab"]),
          _createVNode(_component_n_tab_pane, {
            name: _ctx.thirdTab,
            tab: _ctx.thirdTab,
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.navigate('/search-transaction')))
          }, null, 8, ["name", "tab"])
        ]),
        _: 1
      }, 8, ["value"]),
      _createVNode(_component_router_view)
    ]),
    _: 1
  }))
}