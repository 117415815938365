// TODO: export from multi-provider
export type TokenIdentifier = {
  domain: string | number;
  id: string;
}

export type devTokenName = 'CELO' | 'KWETH' | 'RWETH'

// IMPORTANT make name same as tokens[token].symbol value
const CELO: TokenIdentifier = {
  domain: 'alfajores',
  id: '0xF194afDf50B03e69Bd7D057c1Aa9e10c9954E4C9'
}

const kWETH: TokenIdentifier = {
  domain: 'kovan',
  id: '0xd0a1e359811322d97991e03f863a0c30c2cf029c'
}

const rWETH: TokenIdentifier = {
  domain: 'rinkeby',
  id: '0xc778417e063141139fce010982780140aa0cd5ab'
}

const USDC: TokenIdentifier = {
  domain: 'kovan',
  id: '0xb7a4f3e9097c08da09517b5ab877f7a917224ede'
}

const USDT: TokenIdentifier = {
  domain: 'kovan',
  id: '0x13512979ade267ab5100878e2e0f485b568328a4'
}

const DAI: TokenIdentifier = {
  domain: 'kovan',
  id: '0xFf795577d9AC8bD7D90Ee22b6C1703490b6512FD'
}

const CUSD: TokenIdentifier = {
  domain: 'alfajores',
  id: '0x874069Fa1Eb16D44d622F2e0Ca25eeA172369bC1'
}

const CEUR: TokenIdentifier = {
  domain: 'alfajores',
  id: '0x10c892A6EC43a53E45D0B916B4b7D383B1b78C0F'
}

const SUSHI: TokenIdentifier = {
  domain: 'kovan',
  id: '0x33a368b290589ce8cf781ab4331fe52e77478736'
}

// const WMATIC: TokenIdentifier = {
//   domain: 'polygon',
//   id: '0x5489d5080337347c909382ca7e0037646970eec2'
// }

// const MATIC: TokenIdentifier = {
//   domain: 'polygon',
//   id: '0x9791c9dF02D34F2e7d7322D655535d9849E8da5c'
// }

// const USDCPOS: TokenIdentifier = {
//   domain: 'polygon',
//   id: '0xfcbb489df43f18ab2815cee1da1ed5a0e1565a59'
// }

export default {
  CELO,
  kWETH,
  rWETH,
  USDC,
  USDT,
  DAI,
  CUSD,
  CEUR,
  SUSHI
  // WMATIC,
  // MATIC,
  // USDCPOS
}
