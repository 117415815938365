
import { defineComponent } from 'vue'
import { NCard, NButton, NSteps, NStep, NDivider, NH3, NTag, NAlert } from 'naive-ui'
import { BigNumber } from 'ethers'

import TransactionSuccess from './TransactionSuccess.vue'
import { supportLink, timeTooltipError } from '@/i18n/main.json'
import { getHoursAgo, parseTimestamp } from '@/utils/time'
import { s3BucketUrl, requiresManualProcessing as requiresManualProcessingFromConfig } from '../../config'
import { useStore } from '../../store'

const requiresManualProcessing = requiresManualProcessingFromConfig || (() => false)

export default defineComponent({
  components: {
    NCard,
    NButton,
    NSteps,
    NStep,
    NDivider,
    NH3,
    NTag,
    NAlert,
    TransactionSuccess
  },

  props: {
    txDetails: Object
  },

  data () {
    return {
      steps: [{
        title: 'Dispatched',
        time: '(A few minutes)'
      }, {
        title: 'Included',
        time: '(Less than 1 hour)'
      }, {
        title: 'Relayed',
        time: '(1 hour)'
      }, {
        title: 'Processed',
        time: 'Funds have arrived'
      }],

      supportLink,
      timeTooltipError,
      proof: undefined
    }
  },

  created () {
    // fetch the data when the view is created and the data is
    // already being observed
    this.fetchProof()
  },

  setup () {
    const store = useStore()
    return { store }
  },

  methods: {
    getHoursAgo,
    parseTimestamp,
    triggerTXProcessing () {
      // TODO send tx to smart contract with the proof.
      const send = async () => {
        await this.store.dispatch('setWalletNetwork', this.txDetails!.destination)
        const replica = this.store.getters.getReplica(this.txDetails!.origin, this.txDetails!.destination)
        // @ts-ignore
        const tx = await replica.proveAndProcess(this.proof.message, this.proof.proof.path, this.proof.proof.index)
        await tx.wait(2)
        alert('Message processed!')
      }
      send()
    },
    fetchProof () {
      if (this.proof) {
        return
      }
      setTimeout(async () => {
        if (!this.txDetails) {
          return this.fetchProof()
        }
        if (requiresManualProcessing(this.txDetails!.destination)) {
          try {
            const proofURL = `${s3BucketUrl}${this.txDetails?.origin}_${this.txDetails!.leafIndex}`
            const response = await fetch(proofURL)
            if (!response.ok || response.status >= 400) {
              this.fetchProof()
            }
            const proof = await response.json()
            this.proof = proof
          } catch (error) {
            console.error(error)
            this.fetchProof()
          }
        }
        // TODO How often?
      }, 1000)
    }
  },

  computed: {
    current () {
      switch (this.txDetails!.status) {
        case 'Dispatched':
          return 1
        case 'Included':
          return 2
        case 'Relayed':
          return 3
        case 'Processed':
          return 4
        default:
          return 0
      }
    },

    confirmAt () {
      const { confirmAt, status } = this.txDetails!
      // add 15 minutes
      const confirmationTime = confirmAt.add(BigNumber.from('54000')).toNumber()

      if (confirmAt.isZero()) {
        // tx has not been relayed, no confirmation time available
        return 'About 1 hour'
      } else if (status === 'Processed') {
        // tx has been confirmed and processed
        return 'Delivered'
      } else if (Date.now() > confirmationTime) {
        // tx is ready to be confirmed
        return 'Processing...'
      }

      const parsed = parseTimestamp(confirmationTime) as any
      return `About ${parsed.time}`
    },

    readyForProcessing () {
      const { confirmAt } = this.txDetails!
      // add 15 minutes
      const confirmationTime = confirmAt.add(BigNumber.from('54000')).toNumber()
      return this.txDetails && !!this.proof && Date.now() > confirmationTime /// && proof exists and fraud delay is over
    },

    requiresManualProcessing () {
      return this.txDetails && this.txDetails.status === 'Relayed' && requiresManualProcessing(this.txDetails.destination)
    },

    healthy () {
      const { confirmAt, timestamp } = this.txDetails!
      if (timestamp) {
        if (getHoursAgo(timestamp) <= 5) {
          return true
        }
      } else if (confirmAt.isZero()) {
        return true
      } else if (getHoursAgo(confirmAt.add(BigNumber.from('54000'))) <= 2) {
        return true
      }
      return false
    }
  }
})

