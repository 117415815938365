/**
 * This is the store root where we assemble modules and export
 * the store.
 * - Application-level state is centralized here
 * - Modules are sections of the store. Splitting logic into
 * modules helps maintain logical readability
 * - State is mutated by committing mutations, which are
 * syncronous transactions
 * - Asynchronous logic should be encapsulated in, and can be
 * composed with actions (chaining together mutations if
 * needed)
 */
// TODO: add console logs every time action is dispatched to help with tracing/debugging
import { InjectionKey } from 'vue'
import { createStore, useStore as baseUseStore, Store } from 'vuex'
import walletModule, { WalletState } from './modules/wallet'
import popupModule, { PopupState } from './modules/popups'
import multiProviderModule, { ProviderState } from './modules/multiProvider'
import userInputModule, { UserInputState } from './modules/userInput'
import transactionsModule, { TransactionsState } from './modules/transactions'
import sanctionedListModule, { SanctionedListState } from './modules/sanctionedList'

export interface State {
  wallet: WalletState;
  multiProvider: ProviderState;
  popups: PopupState;
  userInput: UserInputState;
  transactions: TransactionsState;
  sanctionedList: SanctionedListState;
}

export const key: InjectionKey<Store<State>> = Symbol('baseStore')

export const store = createStore({
  modules: {
    wallet: walletModule,
    multiProvider: multiProviderModule,
    popups: popupModule,
    userInput: userInputModule,
    transactions: transactionsModule,
    sanctionedList: sanctionedListModule
  }
})

// define your own `useStore` composition function
export function useStore () {
  return baseUseStore(key)
}
