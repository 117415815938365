
import { ref, defineComponent } from 'vue'
import { NCard, NSelect, NH3, NInput, NButton, NText, NIcon, NDivider } from 'naive-ui'
import { SearchOutline } from '@vicons/ionicons5'

import { useStore } from '@/store'
import { TXData } from '@/store/modules/transactions'
import TransactionDetails from './TransactionDetails.vue'
import LoadingText from './LoadingText.vue'
import { generateNetworkOptions } from '@/utils'

export default defineComponent({
  components: {
    NCard,
    NSelect,
    NInput,
    NButton,
    NH3,
    NText,
    NIcon,
    NDivider,
    SearchOutline,
    TransactionDetails,
    LoadingText
  },

  setup () {
    const store = useStore()

    return {
      store
    }
  },

  data () {
    return {
      showModal: ref(false),
      networkOptions: generateNetworkOptions(),
      network: this.$route.query.network || ref(null),
      hash: this.$route.query.hash || ref(null),
      error: false,
      fetchedTx: null,
      fetching: false
    }
  },

  mounted () {
    if (this.network && this.hash) {
      this.addTx()
    }
    // setInterval(() => {
    //   if (this.network && this.hash) {
    //     this.addTx()
    //   }
    // }, 60000)
  },

  methods: {
    async addTx () {
      // clear error if it was set
      if (this.error) {
        this.error = false
      }

      this.fetching = true

      const payload: TXData = {
        network: this.store.getters.resolveDomain(this.network!),
        hash: this.hash! as string,
        timestamp: undefined
      }

      try {
        const data = await this.store.getters.getTxMessage(payload)
        this.store.dispatch('addSearchTransaction', payload)
        this.fetchedTx = data
        this.error = false
      } catch (e) {
        console.log('error fetching tx')
        console.error(e)
        this.error = true
      }

      this.fetching = false
    }
  }
})

