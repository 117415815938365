import { ethers } from 'ethers'
import { getNetworkByChainID } from './index'

declare const window: any

export async function getMetamaskProvider () {
  // Connect to metamask
  const provider = new ethers.providers.Web3Provider(window.ethereum)
  await provider.ready
  const signer = provider.getSigner()
  console.log({ provider, signer })
  return Promise.resolve(provider)
}

export async function getNetwork (provider: any) {
  // TODO: { name } from Alfajores is `unknown`
  const { chainId } = await provider.ready
  const network = getNetworkByChainID(chainId)
  return network.name
}
