
import { defineComponent, computed } from 'vue'
import { useStore } from '../../store'
import Popup from './Popup.vue'

export default defineComponent({
  components: {
    Popup
  },
  setup: () => {
    const store = useStore()

    return {
      popupList: computed(() => store.state.popups.list)
    }
  }
})
