// TODO: make script that compiles this
const ethereum = {
  CUSD: '0xd8f3208c045dd69d27938346275165998359d8ff',
  CELO: '0xc95dc0eceec11ab8b2bfa1aff3c223c5dc006fad',
  SUSHI: '0x6b3595068778dd592e39a122f4f5a5cf09c90fe2',
  WETH: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
  WBTC: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
  UNI: '0x1f9840a85d5af5bf1d1762f925bdaddc4201f984',
  LINK: '0x514910771af9ca656af840dff83e8264ecf986ca',
  USDC: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
  USDCPOS: '0x0000000000000000000000000000000000000000',
  USDT: '0xdac17f958d2ee523a2206206994597c13d831ec7',
  DAI: '0x6b175474e89094c44da98b954eedeac495271d0f',
  CEUR: '0x977453366b8d205f5c9266b6ba271e850a814a50',
  WMATIC: '0x0000000000000000000000000000000000000000',
  SYMM: '0x57db3ffca78dbbe0efa0ec745d55f62aa0cbd345'
}

const celo = {
  SUSHI: '0x29dFce9c22003A4999930382Fd00f9Fd6133Acd1',
  WETH: '0x122013fd7dF1C6F636a5bb8f03108E876548b455',
  WBTC: '0xBAAB46E28388d2779e6E31Fd00cF0e5Ad95E327B',
  UNI: '0x0000000000000000000000000000000000000000',
  LINK: '0x0000000000000000000000000000000000000000',
  USDC: '0xef4229c8c3250C675F21BCefa42f58EfbfF6002a',
  USDCPOS: '0x1bfc26cE035c368503fAE319Cc2596716428ca44',
  USDT: '0x88eeC49252c8cbc039DCdB394c0c2BA2f1637EA0',
  DAI: '0x90Ca507a5D4458a4C6C6249d186b6dCb02a5BCCd',
  CELO: '0x471ece3750da237f93b8e339c536989b8978a438',
  CUSD: '0x765DE816845861e75A25fCA122bb6898B8B1282a',
  CEUR: '0xD8763CBa276a3738E6DE85b4b3bF5FDed6D6cA73',
  WMATIC: '0x2E3487F967DF2Ebc2f236E16f8fCAeac7091324D',
  SYMM: '0x8427bD503dd3169cCC9aFF7326c15258Bc305478'
}

const polygon = {
  SUSHI: '0xa84b7d339570eb053939810f56ab6eef2e0b38cd',
  WETH: '0xac24381947d4f44c2da58881547c0c38196beb07',
  WBTC: '0x15d25a3c47037c133210ab90adae4d51365a1108',
  UNI: '0x0000000000000000000000000000000000000000',
  LINK: '0x0000000000000000000000000000000000000000',
  USDC: '0x22d9c9fc91ebd595af762ed4c9d5cc1d92cabf59',
  USDCPOS: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
  USDT: '0x91ff78ae858498e6f6e884f78b6e5be892c706af',
  DAI: '0x795a8ec05f0c4f0eea7388378b1b890f851c5001',
  CELO: '0x4764ea6d06ce4e503d0bb323913c1e96dcd1a943',
  CUSD: '0x151517af77b06d9593f3ed41abecdd349316e006',
  CEUR: '0x2f0173dfe97a7dc670d5a10b35c4263cfecfa853',
  WMATIC: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270'
}

export default {
  celo,
  ethereum,
  polygon
}
