
import { defineComponent, computed } from 'vue'
import { NAlert } from 'naive-ui'
import { useStore } from '../../store'
import { disclaimer } from '../../i18n/main.json'

export default defineComponent({
  components: {
    NAlert
  },
  data () {
    return {
      show: this.getShow(),
      disclaimer
    }
  },
  setup () {
    const store = useStore()
    return {
      store,
      showDisclaimer: computed(() => store.state.userInput.showDisclaimer)
    }
  },
  methods: {
    getShow () {
      return this.showDisclaimer
    },

    dismiss () {
      this.store.dispatch('dismissDisclaimer')
    }
  }
})
